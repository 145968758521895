.profile {
  width: 100%;
  text-align: center;
  button {
    background: #332486;
    border-radius: 4px;
    padding: 7px 31px;
    color: #fff;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    &.light {
      background-color: #6993FF;
    }
  }
.body{
  max-width: 448px;
}
  .formGroup {
    margin-top: 48px;
    display: flex;
    row-gap: 30px;
    flex-wrap: wrap;
    justify-content: flex-end;
    > div {
      width: 100%;
      text-align: left;
    }

    label {

    }

    input {

    }
    button{
      max-width: max-content;
      margin-top: 50px;
      padding: 12px 32px;
      display: flex;
      align-self: flex-end;

    }
  }

  nav {
    display: flex;
    max-width: 448px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;

    a{
      background: #FFFFFF;
      border: 1px solid rgba(59, 43, 149, 0.26);
      box-shadow: 0 3px 11px rgba(59, 43, 149, 0.26);
      border-radius: 4px;
      padding: 8px 32px;
      font-family: Roboto-Medium,sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */


      color: #332486;
    }
  }

  p {
    font-size: 21px;
  }
}
