.signBanner {
  background-color: #F5F5F5;
  padding: 7.6rem 3rem 25.4rem;
  .logo {
    width: 150px;

    margin: 0 auto 5.1rem;
  }

  form {
    max-width: 51.4rem;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    border-radius: .8rem;
    padding: 6.1rem 5.9rem 6.6rem;
    margin: auto;
  }

  h1 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.0rem;
    line-height: 2.3rem;

    color: #3E4343;
    margin-bottom: 6.4rem;
  }

  label {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 2.6rem;
    color: #565656;

    &[for=rememberMe] {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

  }

  input {
    padding: 9px 15px 10px;

    width: 100%;
    margin-top: .2rem;
    background: #FFFFFF;
    border: 1px solid rgba(51, 36, 134, 0.7);
    border-radius: 4px;
    &::placeholder{
      color: #87899C;
    }
    &[type=checkbox] {
      width: max-content;
      background-color: red;
      margin: 0 .6rem 0 0;

    }
  }

  .btn {
    display: block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;

    color: #FFFFFF;

    background: #332486;
    border-radius: 4px;
    padding: 1.2rem 5rem;
    margin: 12.9rem  auto 0;
  }

  form > div {
    display: flex;
    align-items: center;

    button {
      color: #87899C;
      width: 100%;
      max-width: max-content;
    }
  }
}
