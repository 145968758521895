footer {
  background: #FBFBFD;
  box-shadow: 0 4px 24px rgba(51, 36, 134, 0.14);
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  //z-index: 1;
}
