
.side-menu{
  position: fixed;
  background: #1B105A;
  width: 265px;
  height: 100%;
  transition: width .2s ease-in;
}

.side-menu.inactive{
  width: 80px;
  background-color: #666;
}

.side-menu .side-header{
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 30px 11px;

}
.side-menu.inactive .side-header{
  padding: 7px 20px;
  height: 38px;
}


.side-menu .side-header .logo{
  width: 71px;
  display: flex;
  align-items: center;

}
.side-menu .side-header .logo img{
  max-width: 100%;
  max-height: 100%;
}

.side-menu .side-header .toggle-menu-btn{
  color: #666;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}
.side-menu .side-header .toggle-menu-btn i{
  font-size: 21px;

}

.side-menu.inactive .side-header .toggle-menu-btn{
  right: -17px;
}

.side-menu.inactive .side-header .toggle-menu-btn i{
  font-size: 17px;
}
.side-menu.inactive .main-menu .menu-item{
  justify-content: center;
  padding: 10px 20px;

}
.side-menu .main-menu .menu-item{

  cursor: pointer;
  box-shadow: 0 0 3px red;
  display: flex;
  align-items: center;
  padding: 15px 30px;
}

.side-menu .main-menu .menu-item.active span{
  color: #fff;
}
.side-menu .main-menu .menu-item.active{
  background: #3B2B95;
  border-radius: 5px;

}


.side-menu .main-menu{
  margin: 5px 0;
  position: relative;
}


.side-menu .main-menu .menu-item .menu-icon{
  width: 100%;
  max-width: 30px;
  height: 30px;
}
.side-menu.inactive .main-menu .menu-item .menu-icon {
  max-width: 25px;
  height: 25px;
}
.side-menu .main-menu .menu-item .menu-icon img{
  width: 100%;
}

.side-menu .main-menu .menu-item span{
margin-left: 6px;
  opacity: 1;
  transition: opacity .2s ease-in;
  color: #C2BFDD;
}

.side-menu.inactive .main-menu .menu-item span{
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  margin-left: 0;
}

.side-menu .main-menu .sub-menu{
  color: #333;
  margin-left: 20px;
  border-left: 1px solid #666;
  box-sizing: border-box;
  padding-left: 30px;
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s ease-in;
}



.side-menu .main-menu .sub-menu.active{
  max-height: 200px;
}

.side-menu .main-menu .sub-menu a{
  display: block;
  margin: 5px 0;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  color: #c3bde7;
  box-sizing: border-box;
  padding: 5px;
}


.side-menu .main-menu .sub-menu a.active{
  color: #ffff;

}

.divider{
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #333;
}

.App > .body{
  margin-left: 265px;
  transition: margin-left .2s ease-in;

}
.body.inactive{
  margin-left: 80px;
  transition: margin-left .5s ease-in;

}


.body.inactive section{
  padding: 30px;
}


