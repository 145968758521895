section{
    background: #FBFBFD;
    box-shadow: 0 4px 24px rgba(51, 36, 134, 0.14);
    margin: 86px 30px 30px;
    padding: 35px 31px;
    position: relative;
    min-height: 649px;
}
h1{
    position: absolute;
    top: -62px;
    left: 0;
}
