.account {



  .imageBanner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .imgContent {
      width: 136px;
      height: 138px;
      border: 1px solid #666;
      border-radius: 8px;

      img {

      }
    }

    .controls {
      .btnsContent {
        display: flex;
        column-gap: 36px;
        margin-bottom: 20px;
      }

      .info {
        font-size: 12px;
        line-height: 14px;

        color: #464343;

      }
    }



  }

}
