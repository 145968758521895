.formGroup{
max-width: 500px;
  text-align: left;
  display: flex;
  flex-direction: column;
row-gap: 32px;
  button{
    margin-top: 65px;
    background: #332486;
    border-radius: 4px;
    padding: 7px 31px;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: max-content;
  }
  }