.agreements {
  //background-color: rgba(0,0,0,0.3);
  width: 100%;
  //height: 500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
flex-wrap: wrap;
  p {
    font-size: 21px;
  }


.footContent{
  width: 100%;
  height: 56px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div{
    display: flex;
    align-items: center;
  }
  select{

  }
  .paginator{
    margin: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{

    }
    button{
      margin: 0 15px;
    }

  }
  label{
    display: flex;
    align-items: center;
    max-width: 110px;
    span{
      width: 100%;
      margin-right: 10px;
    }
  }
}
  table {
    max-width: 100%;
  }

  thead,
  tr {
    border-bottom: 1px solid #C4C4C4;

    &:last-child {
      border-bottom: 0;
    }
  }

  th, td {
    font-weight: 400;
    padding: 13px 18px;
    background-color: #ffffff;
    border: none;
  }
}


//


.container{
  border: 1px solid green;
  margin: 30px;
}
.postsContent{
  font-family: sans-serif;
  font-size: 14px;
}
.postItem{
  border: 1px solid red;
  margin-bottom: 20px;
}
.postItem .title{

}
.postItem .body{

}

.pagination{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination > a{
  background-color: #000;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  text-decoration: none;

}

.pagination > a:last-child {
  margin-right: 0;
}
