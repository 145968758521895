.charge{
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    font-size: 21px;
  }
}