.detailsSingleContainer {
  h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */

    margin-bottom: 6px;
    color: #000000;
  }

  .formGroup {
    max-width: 395px;
    text-align: left;
    padding: 21px 30px;

    label {
      font-family: 'Roboto-Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */


      color: #565656;
    }
  }

  .body {
    display: grid;
    width: 100%;
    //height: 100vh;
    grid-template-columns: 440px 440px 590px;
    grid-template-rows: 410px 410px 410px;
    grid-gap: 30px;
    grid-template-areas:
      "certificatePhoto formContent formContent"
      "certificateQRcode baseValue baseValue"
      "selectsContent insurance insurance";

    > div {
      background: #FBFBFD;
      box-shadow: 0 4px 24px rgba(51, 36, 134, 0.14);
      //height: 410px;
    }
  }

  .certificatePhoto {
    grid-area: certificatePhoto;
    display: flex;
    align-items: center;
    justify-content: center;

    .photo {
      width: 100%;
      max-width: 220px;
      height: 194px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      box-shadow: 1px 1px 11px rgba(51, 36, 134, 0.2);

      p {
        max-width: 70%;
        font-family: 'Roboto-Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        height: max-content;
        color: #565656;
      }
    }
  }

  .formContent {
    grid-area: formContent;
    background-color: red;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 102px;
    row-gap: 15px;
    padding: 40px 30px;
    text-align: left;

    label {
      font-family: 'Roboto-Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #565656;

    }

    > div {
      width: 49%;
      max-width: 395px;
    }
  }

  .certificateQRcode {
    grid-area: certificateQRcode;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {

    }

    p {
      font-family: 'Roboto-Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;

      color: #565656;
    }
  }

  .baseValue {
    grid-area: baseValue;
  }

  .selectsContent {
    grid-area: selectsContent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
box-shadow: none !important;
    h3{
      margin-bottom: 22px;
    }
    > div {
      width: 100%;
      height: 47%;
      background: #FBFBFD;
      box-shadow: 0px 4px 24px rgba(51, 36, 134, 0.14);
      max-width: 100%;
    }
  }

  .insurance {
    grid-area: insurance;

  }

}
