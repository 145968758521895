* {
  margin: 0;
  padding: 0;
  outline: none;
  color: #101820;
  box-sizing: border-box;
  font-family: Roboto-Regular, sans-serif ;

  font-size: 10px;
  line-height: 10px;
}

body * {
  font-size: 1.6rem;
  line-height: 3.2rem;
}
button{
  cursor: pointer;
}
img {
  display: block;
  max-width: 100%;
  height: max-content;
  object-fit: contain;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #d9d9d9 0%, rgba(227, 228, 229, 0) 100%);
  border-radius: 10px;
}


label, select {
  width: 100%;
  max-width: 39.5rem;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #565656;
  margin-bottom: .2rem;


}

select {
  padding: 1rem 1.5rem;
  border: 1px solid rgba(51, 36, 134, 0.6);
  border-radius: 4px;
display: block;

}

input {
  width: 100%;
  padding: 1rem 1.5rem;
  border: 1px solid rgba(51, 36, 134, 0.6);
  border-radius: 4px;
  line-height: initial;

  &::placeholder {
    color: #A1A5B6;
    text-transform: capitalize;
  }
}
section{
  text-align: center;
}


.headContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DEDBF0;
  padding: 32px 0;
  .searchContent {
    position: relative;
    background: #FFFFFF;
    width: 100%;
    max-width: 396px;
    /* text/DEDBF0 */

    border: 1px solid #DEDBF0;
    border-radius: 4px;

    input {
    }

    img {
      position: absolute;
      right: 22px;
      top: 0;
      bottom: 0;
      z-index: 1;
      margin: auto;

    }
  }

  > button {
    padding: 7.5px 20px;
    background: #332486;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    /* identical to box height, or 164% */
    margin-right: 135px;

    /* FFFFFF */

    color: #FFFFFF;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 8px;
    line-height: 8px;
  }

}
@media screen and (max-width: 992px) {
  html {
    font-size: 7px;
    line-height: 7px;
  }
}

@media screen and (max-width: 769px) {
  html {
    font-size: 6px;
    line-height: 6px;
  }
}
@media screen and (max-width: 480px) {
  html {
    font-size: 5px;
    line-height: 5px;
  }
}

@media screen and (max-width: 350px) {
  html {
    font-size: 4px;
    line-height: 4px;
  }
}
