.tableContainer {
  table {
    width: 100%;
    border-collapse: collapse;

  }

  tr {
    border-bottom: 1px solid #C4C4C4;

  }

  td, th {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 160% */

    padding: 10px 0;
    color: #3E4343;

  }

  th {
    color: #332486;
    font-weight: 500;

    width: max-content
  }
.btnsContent{
  display: flex;
  column-gap: 10px;
  justify-content: center;
  > button{
    border-radius: 4px;

  }
}
  .footController {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    margin-top: 140px;
    padding: 20px 30px;
  }

  .showItemsSelect {
    width: max-content;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */


    /* text/454163 */
    padding: 10px;
    color: #454163;
    margin-right: 20px;
  }

  .arrowsContent {
  }

  .infoPage {
    display: flex;
    align-items: center;

    p {
      margin-right: 5px;
    }
  }

  .pageQuantity {
    margin: 0 30px;
    display: flex;
    align-items: center;
    column-gap: 3px;

    .pageQuantityContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 20px;
      column-gap: 5px;

      button {
        &:last-child {
          margin-right: 0;
        }
      }
    }

    button {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nextOneStep {

      img {
        width: 70%;
        height: 70%;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    a {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height */
      margin-right: 9px;

      /* text/232131 */

      color: #232131;

    }
  }

  .goToPage {
    display: flex;
    align-items: center;
    color: #454163;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */


    /* text/9A97B0 */

    p {
      margin-right: 10px;

      color: #9A97B0;
    }

    input {
      padding: 10px;
      text-align: center;
    }
  }

}
