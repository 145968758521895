header {
  background: #FBFBFD;
  box-shadow: 0 4px 24px rgba(51, 36, 134, 0.14);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
  padding: 17px 0;
}

.body.inactive header {
  height: 38px;
  position: inherit;
}

header nav {
  margin: 0 15px;
  padding:0 30px;

  display: flex;
  align-items: center;
  background-color: #fff;
  column-gap: 20px;
}

.notificationBtn {

}

.adminName {

  color: #333232;

}

.dropDownContent {
  position: relative;

  > img {
    border-radius: 50%;

  }

  .menu {
    position: absolute;
    right: 0;
    margin-top: 17px;
    background: #FFFFFF;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    z-index: 1;
    a {
      display: flex;
      align-items: center;
      min-width: max-content;
      padding: 14px 15px 14px 32px;
      font-size: 14px;
      line-height: 16px;
      color: #464343;
      border-bottom: 1px solid #DEDFE0;
      &:last-child{
        border: none;
      }
    }

    img {
      width: 13px;
      margin-right: 15px;
    }

  }
}
